// store our env variables in config object
/*eslint-disable*/
import * as msal from '@azure/msal-browser'


export default {
  baseEndpoint: `https://user-mgmt-hq-service.azurewebsites.net/`,
  integrationsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/get-applications?code=W3xaZhw3VkUMR8uTAcSiaBTZcbzraxZcTtUuyIaB8JM49FJfF077PA==`,
  serviceazure: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-azure.azurewebsites.net/api/azure/users?code=tHttety9YKOIlSZTG0nnT6DtNxhoCWWmWe3hvqZUZZssnCu8EfrTag==`,
    filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-azure.azurewebsites.net/api/azure/filters?code=tHttety9YKOIlSZTG0nnT6DtNxhoCWWmWe3hvqZUZZssnCu8EfrTag==`,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-azure.azurewebsites.net/api/azure/credentials?code=tHttety9YKOIlSZTG0nnT6DtNxhoCWWmWe3hvqZUZZssnCu8EfrTag==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-azure.azurewebsites.net/api/azure/auth?code=tHttety9YKOIlSZTG0nnT6DtNxhoCWWmWe3hvqZUZZssnCu8EfrTag==`,
  },
  serviceatlassian: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-atlassian.azurewebsites.net/api/atlassian/users?code=CuWO2rNwVm9spbyTI4ie6q1ITZzpdlUzTDt68ueX/2wEGGEYvMvanA==`,
    filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-atlassian.azurewebsites.net/api/atlassian/filters?code=CuWO2rNwVm9spbyTI4ie6q1ITZzpdlUzTDt68ueX/2wEGGEYvMvanA==`,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-atlassian.azurewebsites.net/api/atlassian/credentials?code=CuWO2rNwVm9spbyTI4ie6q1ITZzpdlUzTDt68ueX/2wEGGEYvMvanA==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-atlassian.azurewebsites.net/api/atlassian/auth?code=CuWO2rNwVm9spbyTI4ie6q1ITZzpdlUzTDt68ueX/2wEGGEYvMvanA==`,
  },
  serviceconcur: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-concur.azurewebsites.net/api/concur/users?code=s4ecJS-I53o3IR0C1BwGmZlQggfsHy6ZfX7sjYSl-L3zAzFuaOUZ-A==`,
    filtersendpoint: ``,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-concur.azurewebsites.net/api/concur/credentials?code=s4ecJS-I53o3IR0C1BwGmZlQggfsHy6ZfX7sjYSl-L3zAzFuaOUZ-A==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-concur.azurewebsites.net/api/concur/auth?code=s4ecJS-I53o3IR0C1BwGmZlQggfsHy6ZfX7sjYSl-L3zAzFuaOUZ-A==`,
  },
  servicedynamicsfinance: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-dynamicsfinance.azurewebsites.net/api/dynamicsfinance/users?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-dynamicsfinance.azurewebsites.net/api/dynamicsfinance/filters?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-dynamicsfinance.azurewebsites.net/api/dynamicsfinance/credentials?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-dynamicsfinance.azurewebsites.net/api/dynamicsfinance/auth?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  },
  servicedynamicscrm: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-dynamicscrm.azurewebsites.net/api/dynamicscrm/users?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-dynamicsfinance.azurewebsites.net/api/dynamicsfinance/filters?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-dynamicscrm.azurewebsites.net/api/dynamicscrm/credentials?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-dynamicscrm.azurewebsites.net/api/dynamicscrm/auth?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  },
  servicefront: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-front.azurewebsites.net/api/front/users?code=5LAL6Nj9-3vsLyuQY-4ngBZL4MAm44u91la9EiSZgkdVAzFueX4M3Q==`,
    filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-front.azurewebsites.net/api/front/filters?code=5LAL6Nj9-3vsLyuQY-4ngBZL4MAm44u91la9EiSZgkdVAzFueX4M3Q==`,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-front.azurewebsites.net/api/front/credentials?code=5LAL6Nj9-3vsLyuQY-4ngBZL4MAm44u91la9EiSZgkdVAzFueX4M3Q==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-front.azurewebsites.net/api/front/auth?code=5LAL6Nj9-3vsLyuQY-4ngBZL4MAm44u91la9EiSZgkdVAzFueX4M3Q==`,
  },
  servicegoogle: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-google.azurewebsites.net/api/google/users?code=FJPJ1O621YAcgrHLbILbiNFyWg7rZbAIklhjllB2_jBYAzFuAU69-A==`,
    filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-google.azurewebsites.net/api/google/filters?code=FJPJ1O621YAcgrHLbILbiNFyWg7rZbAIklhjllB2_jBYAzFuAU69-A==`,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-google.azurewebsites.net/api/google/credentials?code=FJPJ1O621YAcgrHLbILbiNFyWg7rZbAIklhjllB2_jBYAzFuAU69-A==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-google.azurewebsites.net/api/google/auth?code=FJPJ1O621YAcgrHLbILbiNFyWg7rZbAIklhjllB2_jBYAzFuAU69-A==`,
  },
  serviceincontact: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-incontact.azurewebsites.net/api/incontact/users?code=wWMIytxvxKMeE43W3oGrNuhgWsmb7OA2PxhX8wtAp0cDAzFuWj2oqw==`,
    filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-incontact.azurewebsites.net/api/incontact/filters?code=wWMIytxvxKMeE43W3oGrNuhgWsmb7OA2PxhX8wtAp0cDAzFuWj2oqw==`,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-incontact.azurewebsites.net/api/incontact/credentials?code=wWMIytxvxKMeE43W3oGrNuhgWsmb7OA2PxhX8wtAp0cDAzFuWj2oqw==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-incontact.azurewebsites.net/api/incontact/auth?code=wWMIytxvxKMeE43W3oGrNuhgWsmb7OA2PxhX8wtAp0cDAzFuWj2oqw==`,
  },
  servicejobvite: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-jobvite.azurewebsites.net/api/jobvite/users?code=Vswoenav1gBaoQQXOYtg4zefT31w6VUigBWMXSejcAhjC5cTdHIIhw==`,
    filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-jobvite.azurewebsites.net/api/jobvite/filters?code=Vswoenav1gBaoQQXOYtg4zefT31w6VUigBWMXSejcAhjC5cTdHIIhw==`,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-jobvite.azurewebsites.net/api/jobvite/credentials?code=Vswoenav1gBaoQQXOYtg4zefT31w6VUigBWMXSejcAhjC5cTdHIIhw==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-jobvite.azurewebsites.net/api/jobvite/auth?code=Vswoenav1gBaoQQXOYtg4zefT31w6VUigBWMXSejcAhjC5cTdHIIhw==`,
  },
  serviceknowbe4: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-knowbe4.azurewebsites.net/api/knowbe4/users?code=gZeGMeRvr//4yDZhmd6l1CLu1ipR/VGdNx80/IllwwFLXzluLw8y6A==`,
    filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-knowbe4.azurewebsites.net/api/knowbe4/filters?code=gZeGMeRvr//4yDZhmd6l1CLu1ipR/VGdNx80/IllwwFLXzluLw8y6A==`,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-knowbe4.azurewebsites.net/api/knowbe4/credentials?code=gZeGMeRvr//4yDZhmd6l1CLu1ipR/VGdNx80/IllwwFLXzluLw8y6A==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-knowbe4.azurewebsites.net/api/knowbe4/auth?code=gZeGMeRvr//4yDZhmd6l1CLu1ipR/VGdNx80/IllwwFLXzluLw8y6A==`,
  },
  serviceonelogin: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-onelogin.azurewebsites.net/api/onelogin/users?code=DYid8QV6SaayYk7JG9L5RJwbOjU8205anDyFL9hRro1OS7gjbwzv3w==`,
    filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-onelogin.azurewebsites.net/api/onelogin/filters?code=DYid8QV6SaayYk7JG9L5RJwbOjU8205anDyFL9hRro1OS7gjbwzv3w==`,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-onelogin.azurewebsites.net/api/onelogin/credentials?code=DYid8QV6SaayYk7JG9L5RJwbOjU8205anDyFL9hRro1OS7gjbwzv3w==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-onelogin.azurewebsites.net/api/onelogin/auth?code=DYid8QV6SaayYk7JG9L5RJwbOjU8205anDyFL9hRro1OS7gjbwzv3w==`,
  },
  servicepaylocity: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-paylocity.azurewebsites.net/api/paylocity/users?code=FZ9U9Oauy4i9oUX6NmzT7eRxYKxfUlK6tB5TWuPePoFIAzFurjga8Q==`,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-paylocity.azurewebsites.net/api/paylocity/credentials?code=FZ9U9Oauy4i9oUX6NmzT7eRxYKxfUlK6tB5TWuPePoFIAzFurjga8Q==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-paylocity.azurewebsites.net/api/paylocity/auth?code=FZ9U9Oauy4i9oUX6NmzT7eRxYKxfUlK6tB5TWuPePoFIAzFurjga8Q==`,
  },
  serviceringcentral: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-ringcentral.azurewebsites.net/api/ringcentral/users?code=sT5YjU5W0EWHBXoIXfq1j9ubJzGhdmwQyyC4kTzMgaRbI0Ci916rRA==`,
    filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-ringcentral.azurewebsites.net/api/ringcentral/filters?code=sT5YjU5W0EWHBXoIXfq1j9ubJzGhdmwQyyC4kTzMgaRbI0Ci916rRA==`,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-ringcentral.azurewebsites.net/api/ringcentral/credentials?code=sT5YjU5W0EWHBXoIXfq1j9ubJzGhdmwQyyC4kTzMgaRbI0Ci916rRA==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-ringcentral.azurewebsites.net/api/ringcentral/auth?code=sT5YjU5W0EWHBXoIXfq1j9ubJzGhdmwQyyC4kTzMgaRbI0Ci916rRA==`,
  },
  servicesalesforce: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-salesforce.azurewebsites.net/api/salesforce/users?code=xpkEv4Zo-adh9uVWkGEejs72Qov6UA5nywPJ0tJOu_QyAzFuPayiVA==`,
    filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-salesforce.azurewebsites.net/api/salesforce/filters?code=xpkEv4Zo-adh9uVWkGEejs72Qov6UA5nywPJ0tJOu_QyAzFuPayiVA==`,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-salesforce.azurewebsites.net/api/salesforce/credentials?code=xpkEv4Zo-adh9uVWkGEejs72Qov6UA5nywPJ0tJOu_QyAzFuPayiVA==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-salesforce.azurewebsites.net/api/salesforce/auth?code=xpkEv4Zo-adh9uVWkGEejs72Qov6UA5nywPJ0tJOu_QyAzFuPayiVA==`,
  },
  servicesnipe: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-snipe.azurewebsites.net/api/snipe/users?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    assetsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-snipe.azurewebsites.net/api/snipe/assets?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    filtersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-snipe.azurewebsites.net/api/snipe/filters?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-snipe.azurewebsites.net/api/snipe/credentials?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-snipe.azurewebsites.net/api/snipe/auth?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  },
  servicetrinet: {
    usersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-trinet.azurewebsites.net/api/trinet/users?code=0-hL8EDfeGuIRuX4PdbtXpe8BpvQIzEXf0vblvfIVMb4AzFugxGWfg==`,
    credsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-trinet.azurewebsites.net/api/trinet/credentials?code=0-hL8EDfeGuIRuX4PdbtXpe8BpvQIzEXf0vblvfIVMb4AzFugxGWfg==`,
    authendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-trinet.azurewebsites.net/api/trinet/auth?code=0-hL8EDfeGuIRuX4PdbtXpe8BpvQIzEXf0vblvfIVMb4AzFugxGWfg==`,
  },
  servicearchive: {
    listcontainers: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/list-containers?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    deletecontainers: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/container?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    listblobs: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/drive/list-items?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    downloadblobs: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/drive/download?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    startdrivearchive: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/drive/start?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    startemailarchive: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/email/start?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    startrestorearchive: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/restore/email/start?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    cancelarchive: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/cancel?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    archivestatus: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/status?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
    archiveauth: `https://${process.env.VUE_APP_API_PREPENDER}service-archive.azurewebsites.net/api/archive/credentials?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  },
  servicesystemaccounts: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/whitelist/users?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  serviceauthendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/generate-token?code=W3xaZhw3VkUMR8uTAcSiaBTZcbzraxZcTtUuyIaB8JM49FJfF077PA==`,
  serviceauditendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/audit?code=W3xaZhw3VkUMR8uTAcSiaBTZcbzraxZcTtUuyIaB8JM49FJfF077PA==`,
  serviceautomateendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/automation?code=W3xaZhw3VkUMR8uTAcSiaBTZcbzraxZcTtUuyIaB8JM49FJfF077PA==`,
  serviceautomatestatusendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/automation-status?code=W3xaZhw3VkUMR8uTAcSiaBTZcbzraxZcTtUuyIaB8JM49FJfF077PA==`,
  serviceautomateconfigendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/automation-config?code=W3xaZhw3VkUMR8uTAcSiaBTZcbzraxZcTtUuyIaB8JM49FJfF077PA==`,
  serviceautomateoverrideendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/override?code=DmKyS834f1BdC7I_WVTcZrSKLICBXBBPDmbnIAMe1xcSAzFuj3qYng==`,
  serviceoffboardendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/offboard?code=W3xaZhw3VkUMR8uTAcSiaBTZcbzraxZcTtUuyIaB8JM49FJfF077PA==`,
  serviceonboardendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-provisioning.azurewebsites.net/api/onboard?code=YC03YVRQC2IH3Fit7ojav4X6yeNC8jAqpNxhnTQibZjSAzFuaan-Pw==`,
  servicerunnerendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-automate.azurewebsites.net/api/function-runner?code=Ka2hZQVavEIZNZDP2zFp5olJQ0XMfiETlAEuBnc4x_3GAzFuQrChXw==`,
  servicecontactemailendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/contact-email?code=W3xaZhw3VkUMR8uTAcSiaBTZcbzraxZcTtUuyIaB8JM49FJfF077PA==`,
  servicesupportendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/send-email?code=W3xaZhw3VkUMR8uTAcSiaBTZcbzraxZcTtUuyIaB8JM49FJfF077PA==`,
  servicelistappsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/get-all-applications?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  servicemanageappsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/manage-application?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  servicesearchendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/search?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  serviceusercountendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/reporting/user-count?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==&clientId=audit`,
  servicelicensecountendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/reporting/licenses?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==&clientId=audit`,
  servicemanageb2cusersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/manage/users?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  servicemanagecontractorsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-contractors.azurewebsites.net/api/contractors/users?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  serviceuploadcontractorsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}service-contractors.azurewebsites.net/api/contractors/upload?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  serviceroguereportsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/reporting/audit?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  serviceonboardedusersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/onboard/users?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  serviceoffboardedusersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/termination/users?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  serviceterminatedreportsendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/reporting/termination?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  servicedomainsmanagersendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/domains-managers?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  serviceprovisioningconfigendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/provision-config?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  serviceprovisioningcodeendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/provision-url?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  serviceassetreportendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/reporting/asset-termination?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  servicetenanttypeendpoint: `https://${process.env.VUE_APP_API_PREPENDER}helper-functions.azurewebsites.net/api/manage/environment?code=8C1WPQtKoFGqSvlrpgV7-LMj80wolNOx6QiEotQUkfrvAzFuCtDIng==`,
  authCreds: {
    company_id: process.env.VUE_APP_API_COMPANY_ID,
    client_id: process.env.VUE_APP_API_CLIENT_ID,
    client_secret: process.env.VUE_APP_API_SECRET
  },
  azureConfig: {
    auth: {
      clientId: process.env.VUE_APP_AZURE_CLIENT_ID, // This is the ONLY mandatory field; everything else is optional.
      authority: process.env.VUE_APP_AZURE_AUTHORITY, // Choose sign-up/sign-in user-flow as your default.
      knownAuthorities: [process.env.VUE_APP_AZURE_KNOWN_AUTHORITIES], // You must identify your tenant's domain as a known authority.
      redirectUri: process.env.VUE_APP_AZURE_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
      clientTenantId: process.env.VUE_APP_AZURE_CLIENT_TENANT_ID,
      mspTenantId: process.env.VUE_APP_AZURE_MSP_TENANT_ID,
    },
    cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              // console.error(message);
              return;
            case msal.LogLevel.Info:
              // console.info(message);
              return;
            case msal.LogLevel.Verbose:
              // console.debug(message);
              return;
            case msal.LogLevel.Warning:
              // console.warn(message);
              return;
          }
        }
      }
    }
  },
  serviceLogos: {
    atlassian: '',
    azure: '',
    concur: '',
    jobvite: '',
    knowbe4: '',
    netsuite: '',
    okta: '',
    onelogin: '',
    paylocity: '',
    ringcentral: '',
    salesforce: '',
  },
  catalog: [
    { App: `Atlassian`, servicename: `service-atlassian`, imageurl: `https://dwglogo.com/wp-content/uploads/2016/07/Atlassian-vector.png` },
    { App: `Azure`, servicename: `service-azure`, imageurl: `https://dwglogo.com/wp-content/uploads/2016/07/Atlassian-vector.png` },
    { App: `Jobvite`, servicename: `service-jobvite`, imageurl: `https://dwglogo.com/wp-content/uploads/2016/07/Atlassian-vector.png` },
    { App: `Knowbe4`, servicename: `service-knowbe4`, imageurl: `https://dwglogo.com/wp-content/uploads/2016/07/Atlassian-vector.png` },
    { App: `Onelogin`, servicename: `service-onelogin`, imageurl: `https://dwglogo.com/wp-content/uploads/2016/07/Atlassian-vector.png` },
    { App: `Ringcentral`, servicename: `service-ringcentral`, imageurl: `https://dwglogo.com/wp-content/uploads/2016/07/Atlassian-vector.png` },
    { App: `Salesforce`, servicename: `service-salesforce`, imageurl: `https://dwglogo.com/wp-content/uploads/2016/07/Atlassian-vector.png` },
    { App: `Okta`, servicename: `service-okta`, imageurl: `https://dwglogo.com/wp-content/uploads/2016/07/Atlassian-vector.png` },
    { App: `Netsuite`, servicename: `service-netsuite`, imageurl: `https://dwglogo.com/wp-content/uploads/2016/07/Atlassian-vector.png` },
  ],
}
