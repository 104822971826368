import Vue from 'vue'

// import Vuesax from 'vuesax'
// import 'vuesax/dist/vuesax.css'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// import ToggleButton from 'vue-js-toggle-button'

// globally used in App
Vue.use(BootstrapVue)
// Vue.use(Vuesax)
Vue.use(BootstrapVueIcons)
