/*eslint-disable*/

export default {
    getDate() {
        const date_ob = new Date();
        const day = ("0" + date_ob.getUTCDate()).slice(-2);
        const month = ("0" + (date_ob.getUTCMonth() + 1)).slice(-2);
        const year = date_ob.getUTCFullYear();
        const hours = ("0" + date_ob.getUTCHours()).slice(-2);
        const minutes = date_ob.getUTCMinutes();
        const seconds = date_ob.getUTCSeconds();
        const milliseconds = date_ob.getUTCMilliseconds();

        // puts date in YYYY-MM-DD format to match db format //
        let date_now = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
        let date = new Date(date_now);
        return (date);
    }
}
