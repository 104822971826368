/*eslint-disable*/

// import AuthenticationContext from 'adal-angular/lib/adal.js';
import * as msal from '@azure/msal-browser'
import axios from "axios";
import configFile from "./../config";
import getDate from "./../helperFunctions/getDate";
import store from "./../store";


const config = configFile.azureConfig;
const authCreds = configFile.authCreds;
const serviceauthendpoint = configFile.serviceauthendpoint;
const msalInstance = new msal.PublicClientApplication(config);

export default {
  async getUserProfile() {
    try {
      const redirectResponse = await msalInstance.handleRedirectPromise();
      console.log("Redirect response:", redirectResponse);

      const accounts = msalInstance.getAllAccounts();
      console.log("Accounts:", accounts);

      if (accounts.length === 0) {
        console.log("No accounts found, opening login popup...");
        await this.signIn();
        return null;
      } else if (accounts.length === 1) {
        const account = accounts[0];
        const tenantId = account.tenantId;
        console.log("Tenant ID:", tenantId);

        if (config.auth.mspTenantId == tenantId || config.auth.clientTenantId == tenantId) {
          console.log("Tenant is authorized.");

          try {
            const silentRequest = {
              account: account,
              scopes: ["user.read"]
            };

            const silentResult = await msalInstance.acquireTokenSilent(silentRequest);
            console.log("Silent token acquisition successful:", silentResult);
            return account;
          } catch (silentError) {
            console.log("Silent token acquisition failed, falling back to interactive login:", silentError);
            await this.signIn();
            return 'error';
          }
        } else {
          console.log("Unauthorized tenant, signing out...");
          await this.signOut();
          return null;
        }
      } else {
        console.log("Multiple accounts found:", accounts);
        // Implement logic to handle multiple accounts, if needed
        const account = accounts[0]; // Return the first account as a default behavior
        const tenantId = account.tenantId;
        console.log("Tenant ID:", tenantId);

        if (config.auth.mspTenantId == tenantId || config.auth.clientTenantId == tenantId) {
          console.log("Tenant is authorized.");

          try {
            const silentRequest = {
              account: account,
              scopes: ["user.read"]
            };

            const silentResult = await msalInstance.acquireTokenSilent(silentRequest);
            console.log("Silent token acquisition successful:", silentResult);
            return account;
          } catch (silentError) {
            console.log("Silent token acquisition failed, falling back to interactive login:", silentError);
            await this.signIn();
            return null;
          }
        } else {
          console.log("Unauthorized tenant, signing out...");
          await this.signOut();
          return null;
        }
      }
    } catch (error) {
      console.error("Error in getUserProfile:", error);
      return 'error';
    }
  },
  // async setAccount(account) {
  //   let accountId = account.homeAccountId;
  //   username = account.username;
  //   welcomeUser(username);
  // },
  // async editProfile() {
  //   const editProfileRequest = {
  //     authority: "https://userhq.b2clogin.com/userhq.onmicrosoft.com/B2C_1_edit"
  //   };
  //   authenticationContext.loginRedirect(editProfileRequest);
  // },

  async signIn() {
    try {
      const redirectResponse = await msalInstance.handleRedirectPromise();
      console.log("Redirect response:", redirectResponse);

      const accounts = msalInstance.getAllAccounts();
      console.log("Accounts:", accounts);

      if (accounts.length === 0) {
        console.log("No accounts found, redirecting to login...");
        await msalInstance.loginRedirect({
          scopes: ["user.read"], // Add the scopes you need here
        });
      } else {
        console.log("Accounts found, no need to redirect.");
      }
    } catch (error) {
      console.error("Error in signIn:", error);
      return 'error'
    }
  },
  async signOut() {
    try {
      const accounts = msalInstance.getAllAccounts();
      console.log("Accounts before sign out:", accounts);

      if (accounts.length > 0) {
        console.log("Redirecting to logout...");
        await msalInstance.logoutRedirect();
      } else {
        console.log("No accounts found, no need to sign out.");
      }
    } catch (error) {
      console.error("Error in signOut:", error);
    }
  },

  // Get Token For API
  async getToken() {
    // console.log(authCreds);
    // console.log("IN AUTH GET");
    try {
      let getTokenResponse = await axios.post(
        serviceauthendpoint,
        authCreds
      );
      if ((getTokenResponse.data.status = 200)) {
        let tokenExp = await getTokenResponse.data.expires_at;
        let tokenDate = new Date(tokenExp);
        store.commit("saveToken", getTokenResponse.data.token);
        store.commit("saveTokenExp", tokenDate);
        axios.defaults.headers.common["token"] = store.state.token;
        axios.defaults.headers.common["client_id"] = config.authCreds.company_id;
        return 'success'
      } else {
        return 'success'
      }
    } catch (error) {
      return 'error'
    }
  },
  // Check Token for API (If not there get new one)
  async checkToken() {
    // console.log("IN TOKEN CHECK");
    let token = store.state.token;
    if (token.length == undefined) {
      try {
        await this.getToken()
        return 'success'
      } catch (error) {
        return 'error'
      }
    } else {
      try {
        let tokenExp = store.state.tokenExp;
        let dateNow = getDate.getDate();
        // console.log("DATE CHECKER")
        // console.log(dateNow)
        // console.log(tokenExp)
        if (dateNow > tokenExp) {
          // console.log("DATE CHECKER FAILED, GETTING NEW")
          await this.getToken();
          return 'success'
        }
        axios.defaults.headers.common["token"] = store.state.token;
        axios.defaults.headers.common["client_id"] = config.authCreds.company_id;
        return 'success'
      }
      catch (error) {
        return 'error'
      }
    }
  }
}