import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    servicesObject: {},
    allUsersObject: {},
    csvresults: {},
    whereAmI: {},
    offboardSelected: {},
    onboardBaseData: {},
    automateParent: {},
    automateChildren: {},
    automateInactive: {},
    azureUsers: {},
    azureRoles: {},
    azureFilters: {},
    azureOnboardData: {},
    atlassianUsers: {},
    atlassianRoles: {},
    atlassianFilters: {},
    atlassianOnboardData: {},
    dynamicscrmUsers: {},
    dynamicscrmRoles: {},
    dynamicscrmFilters: {},
    dynamicscrmOnboardData: {},
    dynamicsfinanceUsers: {},
    dynamicsfinanceRoles: {},
    dynamicsfinanceFilters: {},
    dynamicsfinanceOnboardData: {},
    frontUsers: {},
    frontRoles: {},
    frontFilters: {},
    frontOnboardData: {},
    googleUsers: {},
    googleRoles: {},
    googleFilters: {},
    googleOnboardData: {},
    incontactUsers: {},
    incontactRoles: {},
    incontactFilters: {},
    incontactOnboardData: {},
    jobviteUsers: {},
    jobviteRoles: {},
    jobviteFilters: {},
    jobviteOnboardData: {},
    netsuiteUsers: {},
    netsuiteRoles: {},
    netsuiteFilters: {},
    netsuiteOnboardData: {},
    concurUsers: {},
    concurRoles: {},
    concurFilters: {},
    concurOnboardData: {},
    knowbe4Users: {},
    knowbe4Roles: {},
    knowbe4Filters: {},
    knowbe4OnboardData: {},
    oktaUsers: {},
    oktaRoles: {},
    oktaFilters: {},
    oktaOnboardData: {},
    oneloginUsers: {},
    oneloginRoles: {},
    oneloginFilters: {},
    oneloginOnboardData: {},
    paylocityUsers: {},
    paylocityRoles: {},
    paylocityFilters: {},
    paylocityOnboardData: {},
    ringcentralUsers: {},
    ringcentralRoles: {},
    ringcentralFilters: {},
    ringcentralOnboardData: {},
    salesForceUsers: {},
    salesForceRoles: {},
    salesForceFilters: {},
    salesforceOnboardData: {},
    snipeUsers: {},
    snipeAssets: {},
    snipeRoles: {},
    snipeFilters: {},
    snipeOnboardData: {},
    trinetUsers: {},
    trinetRoles: {},
    trinetFilters: {},
    trinetOnboardData: {},
    userToDelete: {},
    selecteduser: {},
    searchUser: {},
    token: {},
    tokenExp: {},
    addResponseConstructor: {},
    deleteResponseConstructor: {},
  },
  mutations: {
    saveToken(state, payload) {
      state.token = payload
    },
    saveTokenExp(state, payload) {
      state.tokenExp = payload
    },
    saveServices(state, payload) {
      state.servicesObject = payload
    },
    savecsvresults(state, payload) {
      state.csvresults = payload
    },
    saveOffboardSelected(state, payload) {
      state.offboardSelected = payload
    },
    saveOnboardBaseData(state, payload) {
      state.onboardBaseData = payload
    },
    saveAutomateParent(state, payload) {
      state.automateParent = payload
    },
    saveAutomateChildren(state, payload) {
      state.automateChildren = payload
    },
    saveAutomateInactive(state, payload) {
      state.automateInactive = payload
    },
    saveAddResponseConstructor(state, payload) {
      state.addResponseConstructor = payload
    },
    saveDeleteResponseConstructor(state, payload) {
      state.deleteResponseConstructor = payload
    },
    saveUserToDelete(state, payload) {
      state.userToDelete = payload
    },
    saveselecteduser(state, payload) {
      state.selecteduser = payload
    },
    saveSearchUser(state, payload) {
      state.searchUser = payload
    },
    saveAzureUsers(state, payload) {
      state.azureUsers = payload
    },
    saveAzureRoles(state, payload) {
      state.azureRoles = payload
    },
    saveAzureFilters(state, payload) {
      state.azureFilters = payload
    },
    saveAzureOnboardData(state, payload) {
      state.azureOnboardData = payload
    },
    saveAtlassianUsers(state, payload) {
      state.atlassianUsers = payload
    },
    saveAtlassianRoles(state, payload) {
      state.atlassianRoles = payload
    },
    saveAtlassianFilters(state, payload) {
      state.atlassianFilters = payload
    },
    saveAtlassianOnboardData(state, payload) {
      state.atlassianOnboardData = payload
    },
    saveDynamicsFinanceUsers(state, payload) {
      state.dynamicsfinanceUsers = payload
    },
    saveDynamicsFinanceRoles(state, payload) {
      state.dynamicsfinanceRoles = payload
    },
    saveDynamicsFinanceFilters(state, payload) {
      state.dynamicsfinanceFilters = payload
    },
    saveDynamicsFinanceOnboardData(state, payload) {
      state.dynamicsfinanceOnboardData = payload
    },
    saveDynamicsCRMUsers(state, payload) {
      state.dynamicscrmUsers = payload
    },
    saveDynamicsCRMRoles(state, payload) {
      state.dynamicscrmRoles = payload
    },
    saveDynamicsCRMFilters(state, payload) {
      state.dynamicscrmFilters = payload
    },
    saveDynamicsCRMOnboardData(state, payload) {
      state.dynamicscrmOnboardData = payload
    },
    saveFrontUsers(state, payload) {
      state.frontUsers = payload
    },
    saveFrontRoles(state, payload) {
      state.frontRoles = payload
    },
    saveFrontFilters(state, payload) {
      state.frontFilters = payload
    },
    saveFrontOnboardData(state, payload) {
      state.frontOnboardData = payload
    },
    saveGoogleUsers(state, payload) {
      state.googleUsers = payload
    },
    saveGoogleRoles(state, payload) {
      state.googleRoles = payload
    },
    saveGoogleFilters(state, payload) {
      state.googleFilters = payload
    },
    saveGoogleOnboardData(state, payload) {
      state.googleOnboardData = payload
    },
    saveIncontactUsers(state, payload) {
      state.incontactUsers = payload
    },
    saveIncontactRoles(state, payload) {
      state.incontactRoles = payload
    },
    saveIncontactFilters(state, payload) {
      state.incontactFilters = payload
    },
    saveIncontactOnboardData(state, payload) {
      state.incontactOnboardData = payload
    },
    saveJobviteUsers(state, payload) {
      state.jobviteUsers = payload
    },
    saveJobviteRoles(state, payload) {
      state.jobviteRoles = payload
    },
    saveJobviteFilters(state, payload) {
      state.jobviteFilters = payload
    },
    saveJobviteOnboardData(state, payload) {
      state.jobviteOnboardData = payload
    },
    saveNetSuiteUsers(state, payload) {
      state.netsuiteUsers = payload
    },
    saveNetSuiteRoles(state, payload) {
      state.netsuiteRoles = payload
    },
    saveNetSuiteFilters(state, payload) {
      state.netsuiteFilters = payload
    },
    saveNetSuiteOnboardData(state, payload) {
      state.netsuiteOnboardData = payload
    },
    saveConcurUsers(state, payload) {
      state.concurUsers = payload
    },
    saveConcurRoles(state, payload) {
      state.concurRoles = payload
    },
    saveConcurFilters(state, payload) {
      state.concurFilters = payload
    },
    saveConcurOnboardData(state, payload) {
      state.concurOnboardData = payload
    },
    saveKnowbe4Users(state, payload) {
      state.knowbe4Users = payload
    },
    saveKnowbe4Roles(state, payload) {
      state.knowbe4Roles = payload
    },
    saveKnowbe4Filters(state, payload) {
      state.knowbe4Filters = payload
    },
    saveKnowbe4OnboardData(state, payload) {
      state.knowbeOnboardData = payload
    },
    saveOktaUsers(state, payload) {
      state.oktaUsers = payload
    },
    saveOktaRoles(state, payload) {
      state.oktaRoles = payload
    },
    saveOktaFilters(state, payload) {
      state.oktaFilters = payload
    },
    saveOktaOnboardData(state, payload) {
      state.oktaOnboardData = payload
    },
    saveOneLoginUsers(state, payload) {
      state.oneloginUsers = payload
    },
    saveOneLoginRoles(state, payload) {
      state.oneloginRoles = payload
    },
    saveOneLoginFilters(state, payload) {
      state.oneloginFilters = payload
    },
    saveOneLoginOnboardData(state, payload) {
      state.oneloginOnboardData = payload
    },
    savePaylocityUsers(state, payload) {
      state.paylocityUsers = payload
    },
    savePaylocityRoles(state, payload) {
      state.paylocityRoles = payload
    },
    savePaylocityFilters(state, payload) {
      state.paylocityFilters = payload
    },
    savePaylocityOnboardData(state, payload) {
      state.paylocityOnboardData = payload
    },
    saveRingCentralUsers(state, payload) {
      state.ringcentralUsers = payload
    },
    saveRingCentralRoles(state, payload) {
      state.ringcentralRoles = payload
    },
    saveRingCentralFilters(state, payload) {
      state.ringcentralFilters = payload
    },
    saveRingCentralOnboardData(state, payload) {
      state.ringcentralOnboardData = payload
    },
    saveSalesForceUsers(state, payload) {
      state.salesforceUsers = payload
    },
    saveSalesForceRoles(state, payload) {
      state.salesforceRoles = payload
    },
    saveSalesForceFilters(state, payload) {
      state.salesforceFilters = payload
    },
    saveSalesForceOnboardData(state, payload) {
      state.salesforceOnboardData = payload
    },
    saveSnipeUsers(state, payload) {
      state.snipeUsers = payload
    },
    saveSnipeAssets(state, payload) {
      state.snipeAssets = payload
    },
    saveSnipeRoles(state, payload) {
      state.snipeRoles = payload
    },
    saveSnipeFilters(state, payload) {
      state.snipeFilters = payload
    },
    saveSnipeOnboardData(state, payload) {
      state.snipeOnboardData = payload
    },
    saveTrinetUsers(state, payload) {
      state.trinetUsers = payload
    },
    saveTrinetRoles(state, payload) {
      state.trinetRoles = payload
    },
    saveTrinetFilters(state, payload) {
      state.trinetFilters = payload
    },
    saveTrinetOnboardData(state, payload) {
      state.trinetOnboardData = payload
    },
    saveLocation(state, payload) {
      state.whereAmI = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
