/*eslint-disable*/
import Vue from 'vue'
import VueRouter from 'vue-router'
import authentication from '../authentication'
import config from '../config'


Vue.use(VueRouter)

const routes = [

  // Lander paths
  {
    path: '/',
    name: 'Home',
    component: () => import('../components/landers/home.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/apps/:app',
    name: 'datatablelanding',
    component: () => import('../components/landers/appsloader.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/search',
    name: 'searchAll',
    component: () => import('../components/landers/offboardComponents/offboard.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/offboarded/users',
    name: 'OffboardedUsers',
    component: () => import('../components/landers/offboardComponents/offboardedtable.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/outstanding/assets',
    name: 'outstandingAssets',
    component: () => import('../components/landers/offboardComponents/outstandingassets.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/onboard',
    name: 'onboard',
    component: () => import('../components/landers/onboard.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/onboarded/users',
    name: 'OnboardedUsers',
    component: () => import('../components/landers/onboardComponents/onboardedtable.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/automation/sync',
    name: 'automatesync',
    component: () => import('../components/landers/automatesync.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/automation/offboard',
    name: 'automateoffboard',
    component: () => import('../components/landers/automateoffboard.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/archive/user',
    name: 'archiveuser',
    component: () => import('../components/landers/archiveuser.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/archive/browse',
    name: 'archivebrowser',
    component: () => import('../components/landers/archivebrowser.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/archive/configure',
    name: 'archiveconfigure',
    component: () => import('../components/landers/archiveconfigure.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/contractors',
    name: 'contractors',
    component: () => import('../components/landers/contractors.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  // {
  //   path: '/reports/licensesandusers',
  //   name: 'userreports',
  //   component: () => import('../components/landers/reportsComponents/reports.vue'),
  //   beforeEnter: async (to, from, next) => {
  //     let userInfo = await authentication.getUserProfile()
  //     if (userInfo.idTokenClaims.extension_company_id == config.authCreds.company_id) {
  //       next()
  //     }
  //     else {
  //       next('/unauthorized')
  //     }
  //   }
  // },
  {
    path: '/reports/rogueusers',
    name: 'roguereports',
    component: () => import('../components/landers/reportsComponents/roguereports.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/reports/terminatedusers',
    name: 'terminatedreports',
    component: () => import('../components/landers/reportsComponents/terminatedreports.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/reports/systemaccounts',
    name: 'systemaccounts',
    component: () => import('../components/landers/reportsComponents/systemaccounts.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/reports/jeremyslist',
    name: 'jeremysreport',
    component: () => import('../components/landers/reportsComponents/jeremyslist.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/integrations',
    name: 'integrations',
    component: () => import('../components/landers/integrations.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: () => import('../components/landers/catalog.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/manage',
    name: 'manage',
    component: () => import('../components/landers/manage.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../components/landers/profile.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  },
  {
    path: '/unauthorized',
    name: '401forbidden',
    component: () => import('../components/landers/401forbidden.vue'),
    beforeEnter: async (to, from, next) => {
      await authentication.getUserProfile()
      next()
    }
  },
  {
    path: '*',
    name: '404notfound',
    component: () => import('../components/landers/404notfound.vue'),
    beforeEnter: async (to, from, next) => {
      let prof = await authentication.getUserProfile()
      if (prof.tenantId == config.azureConfig.auth.clientTenantId || prof.tenantId == config.azureConfig.auth.mspTenantId) {
        next()
      } else {
        next('/unauthorized')
      }
    }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})


export default router
