<template>
  <div id="app">
    <navcomp />
    <router-view />
  </div>
</template>


<script>
export default {
  components: {
    navcomp: () => import(`./components/landers/nav.vue`)
  },
};
</script>

<style scoped></style>